import * as React from 'react';

const Card = ({ name, staticImgSrc, address, cityState, link, children }) => {

  return (
    <div className="p-2 sm:p-0">
      <h2 className="text-2xl">{name}</h2>
      <hr className="my-1" />
      <div className="flex flex-row align justify-between">
        <div>
          {address &&
            <address className="text-gray-500">
              {address}<br />
              {cityState}
            </address>
          }
        </div>

        <div>
          {link && <a className="link" href={link}>Visit Website</a>}
        </div>
      </div>


      <div>
        {children}
      </div>
      {staticImgSrc
        && <img className="w-full max-h-96 object-cover" src={staticImgSrc} alt={name} />}
    </div>
  );


};

export default Card;
