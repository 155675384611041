import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { Section } from '../components/section';


export const query = graphql`
{
  allFile(filter: {sourceInstanceName: {eq: "activities"}, extension: {eq: "mdx"}}
          sort: {order: ASC, fields: childrenMdx___frontmatter___order})
  {
    nodes {
      childrenMdx {
        id
        body
        frontmatter {
          name
          link
          address
          cityState
          type
          imgSrc {
            childImageSharp {
              gatsbyImageData
            }
          }
          staticImgSrc
        }
      }
    }
  }
}`

const ActivitiesPage = ({ data }) => {

  const getNodesForType = (type) => data.allFile.nodes.filter(({ childrenMdx }) => {
    const [node] = childrenMdx;
    return node.frontmatter.type === type;
  });

  return (
    <Layout pageTitle="Activities" pageDescription="Information about activities on Oahu.">
      <article className="container m-auto">
        <h1 className="article-title">Things to do on O'ahu</h1>
        <p className="mx-8 text-center">Oʻahu, known as "the gathering place", is where Woori grew up and where her family continue to call home. Listed below are just a few of our favorite things to do on the island:</p>
        { /* Recommendations */}
        <Section id="EDS" title="Eat/Drink/Shop" nodes={getNodesForType("EDS")} />

        <Section id="Outdoors" title="Outdoors" nodes={getNodesForType("Outdoors")} />

        <Section id="Culture" title="Culture" nodes={getNodesForType("Culture")} />

        <section id="other" className="mt-4">
          <h2 className="header">Other Resources</h2>
          <p>Here are a few of our favorite resources for finding things to do Oahu</p>
          <ul>
            <li>
              <a className="link" href="https://www.eater.com/maps/best-restaurants-honolulu-hawaii-38">Eater's 38 Best Honolulu Restaurants</a>
            </li>
            <li>
              <a className="link" href="https://www.youtube.com/watch?v=-zPQrLwnDI0">Denny and Nikki - 10 Hikes on Oahu (YouTube)</a>
            </li>
            <li>
              <a className="link" href="https://www.youtube.com/watch?v=Qelm_QJeUzg">Denny and Nikki - Top 25 Things to do in Oahu (YouTube)</a>
            </li>
          </ul>
        </section>
      </article>
    </Layout>

  );

}

export default ActivitiesPage
