import * as React from 'react'
import Card from '../components/card'
import { MDXRenderer } from 'gatsby-plugin-mdx';

export const Section = ({ nodes, title }) => {
  return (
    <section className="mt-4">
      <h2 className="header">{title}</h2>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {
          nodes.map(({ childrenMdx }) => {
            const [node] = childrenMdx;
            const { name, address, cityState, link, staticImgSrc } = node.frontmatter;
            return (
              <Card
                key={name}
                name={name}
                address={address}
                cityState={cityState}
                link={link}
                staticImgSrc={staticImgSrc}>
                <MDXRenderer>
                  {node.body}
                </MDXRenderer>
              </Card>
            );
          })
        }
      </div>
    </section>);
}
